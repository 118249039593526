.container-notfound {
    position: relative;
    height: 100vh;
    background-color: rgb(248, 248, 248);
}

.section-notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.notfound {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.notfound h1 {
    font-size: 146px !important;
    font-weight: 700;
    margin: 0px;
    color: #232323;
}

.notfound h2 {
    font-size: 22px;
    font-weight: 700;
    text-transform: uppercase;
    color: #232323;
}

.notfound p {
    color: #787878;
    font-size: 20px;
    font-weight: 300;
}

.notfound a {
    display: inline-block;
    background-color: #0F6631;
    color: white;
    padding: 5px 15px;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    transition: all .3s ease-in-out 0s;
}

.notfound a:hover {
    color: white;
    transform: translateY(-1px);
}

.img-notfound {
    display: inline-block;
    width: 120px;
    height: 120px;
    background-image: url(../../images/emoji.webp);
    background-size: cover;
}
