
.body-login {
    width: 100%;
    margin: 0 auto;
    background-image: linear-gradient(#00000080, #00000080), url(../../images/bg-login.png);
    background-position: top;
    background-size: cover;
  }
  
  .container-login {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 15px;
    opacity: 0.9;
  }
  
  .body-login :not(.body-login) {
    opacity: 1;
  }
  
  .wrap-login {
    width: 410px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    padding: 56px 32px;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.2);
  }
  
  .logo-empresa {
    height: 80px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo-empresa img {
    height: 100%;
  }
  
  .login-form {
    width: 100%;
  }
  
  .login-form-title {
    font-family: Nunito, sans-serif;
    display: block;
    font-size: 28px;
    color: #71717A;
    line-height: 2.2;
    text-align: center;
    margin: 15px 0 35px 0;
  }
  
  .login-form-title img {
    width: 90px;
  }
  
  .wrap-input {
    width: 100%;
    position: relative;
    border: 1px solid #adadad;
    border-radius: 4px;
    margin-bottom: 25px;
  }
  
  .wrap-input-password {
    width: 100%;
    border: 1px solid #adadad;
    border-radius: 4px;
  }
  
  .input {
    font-size: 15px;
    color: #3F3F46;
    line-height: 1.2;
    border: none;
    display: block;
    width: 100%;
    height: 45px;
    background-color: transparent;
    padding: 0 5px;
    font-family: Nunito, sans-serif;
  }
  
  .focus-input {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    color: #adadad;
    font-family: Nunito, sans-serif;
  }  
  
  .input:focus {
    outline: 0;
  }
  
  .input:focus+.focus-input::after {
    top: -15px;
  }
  
  .input:focus+.focus-input::before {
    width: 100%;
  }
  
  .has-val+.focus-input::after {
    top: -20px;
  }
  
  .has-val+.focus-input::before {
    width: 100%;
  }
  
  .container-login-form-btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 13px;
  }
  
  .login-form-btn {
    margin-top: 25px;
    font-size: 15px;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    color: #fff;
    line-height: 1.2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    background: #179A49;
    background: -webkit-linear-gradient(to left, #13784D, #78BA54);
    background: -o-linear-gradient(to left, #13784D, #78BA54);
    background: -moz-linear-gradient(to left, #13784D, #78BA54);
    background: linear-gradient(to left, #13784D, #78BA54);
    transition: 1s;
  }
  
  .login-form-btn:hover {
    transform: scale(1.075);
    cursor: pointer;
  }
  
  .text-center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
  
  .txt1 {
    font-size: 14px;
    color: #adadad;
    line-height: 1.5;
    padding-right: 5px;
  }
  
  .txt2 {
    font-size: 14px;
    color: #eed814;
    line-height: 1.5;
    text-decoration: none;
    transition: 1s;
  }
  
  .txt2:hover {
    color: #b6a82c
  }
  