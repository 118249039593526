@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
@import '../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';

h2 {
  font-size: 16px !important;
  font-weight: 400 !important;
}

h3 {
  font-size: 16px !important;
}

.h3-demanda {
  border-radius: 8px 8px 0 0;
  padding: 16px;
  margin: 0;
}

.card {
  border-radius: 8px;
  padding: 12px 16px;
}

b {
  padding: 0 !important;
}

.App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.AppEmpresa {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  flex-direction: column
}

.error {
  width: 30%;
  position: fixed;
  right: 0px;
  top: 70px;
  margin: 10px;
  background-color: #FEE2E2;
  border-left: 8px solid #EF4444;
  color: #171717;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  z-index: 10;
}

.error .error-icon {
  color: #DC2626;
}

.success {
  width: 30%;
  position: fixed;
  right: 0px;
  top: 70px;
  margin: 10px;
  background-color: #e1faea;
  border-left: 8px solid #00af0f;
  color: #171717;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  z-index: 10;
}

.success .error-icon {
  color: #00af0f;
}

.error-icon {
  font-size: 20px;
  margin-right: 1px;
  margin-right: 10px;
}

.loading-screen {
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loader-container {
  width: 32px;
  height: 32px;
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 0.5rem !important;

}

.css-41abqd-MuiTableContainer-root {
  width: 100%;
  overflow-x: auto;
  max-height: 280px !important;
}

.spinner {
  width: 32px;
  height: 32px;
  border: 3px solid;
  border-color: #fff transparent #fff transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.img-empty {
  width: 500px;
  height: 500px;
}

.header {
  height: 7vh;
  width: 100vw;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2em 30px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  justify-content: space-between;
  z-index: 2;
}

.content-dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.options-dropdown {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.options-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
}

.options-header a,
.options-header a:hover {
  text-decoration: none;
  color: #697a8d;
}

.options-header hr {
  margin: 10px 0;
}

.icon-options {
  font-size: 22px;
  margin: 0 7px;
}

.icon-options-tema {
  font-size: 25px;
  margin: 0 15px;
}

.usuario {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #D66914;
  border-radius: 50%;
  font-weight: 600;
  font-size: 18px;
  color: white;
}

.label-name {
  font-weight: 600;
  font-size: 15px;
  color: #52525B;
}

.label-charge {
  font-size: 12px;
  color: #71717A;
}

.menu {
  font-size: 30px;
  color: #fff;
}

.menu:hover {
  cursor: pointer;
}

.logoMenu {
  width: 60px;
  margin-left: 40px;
  cursor: pointer;
  padding-bottom: 12px;
}

.logoEmpresa {
  width: 208px;
  height: 78px;
  background-repeat: no-repeat;
  background-position: center;
}

.logoEmpresa img {
  height: 100%;
}

.selectEmpresa {
  width: 50%;
  margin: 5em 0em 2em 0em;
}

.selectEmpresa select {
  height: 3em;
  border-radius: 0.5em;
  border: 0.1em solid black;
  font-size: 16px;
  padding: 5px;
}

.btnConfirmar {
  font-size: 15px;
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 10px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to left, #13784D, #78BA54);
  transition: 1s;
}

.btnConfirmar:hover {
  transform: scale(1.075);
  cursor: pointer;
}

.styleSidebar {
  display: flex;
  flex-direction: row;
}

@import url('https://fonts.googleapis.com/css2?family=Knewave&family=Nunito:wght@400;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  font-family: Nunito, sans-serif;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 25px;
  height: 20px;
  left: 25px;
  top: 25px;
}

#react-burger-menu-btn {
  padding-right: 40px !important;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #fff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #fff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  font-size: 14px;
}

/* General sidebar styles */
.bm-menu {
  padding: 10px 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #000;
}

/* Wrapper for item list */
.bm-item-list {
  color: #002fff;
  padding: 0.8em;
}

/* Styling of overlay */
.bm-overlay {
  background: #fff;
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: #000;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s;
}

.ps-menu-icon {
  font-size: 24px;
}

.containerInterno {
  box-sizing: border-box;
}

.container-dashboard {
  padding: 10px 30px;
  width: 100%;
  margin-top: 6vh;
  min-height: 89vh;
}

.header-dashboard {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: end;
  padding: 20px 0px;
}

.header-empresa {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  padding: 20px 0px;
  width: 60%;
  margin: auto;
}

.container-grafico {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.section-grafico {
  border-radius: 8px;
  padding: 20px;
}

.status {
  border-radius: 4px;
  padding: 5px 15px;
  /* min-width: 165px; */
}

.status-concluido {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  background-color: #059669;
  border-radius: 2px;
}

.status-atraso {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  background-color: #F87171;
  border-radius: 2px;
}

.status-andamento {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  background-color: #60A5FA;
  border-radius: 2px;
}

.status-prioritario {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  background-color: #FACC15;
  border-radius: 2px;
}

.container-fases {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 30px 0;
  gap: 30px;
}

.content-fases {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.card-fases {
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 197px;
  height: 72px;
}

.icon-fases {
  border-radius: 4px;
  font-size: 30px;
  width: 56px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.titulo-fases {
  font-size: 14px;
  margin: 0;
}

.qtd-fases {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.section-investimento {
  gap: 30px;
}

.section-investimento div {
  width: 100%;
}

.card-investimento {
  border-radius: 4px;
  padding: 12px 16px;
}

.card-investimento h2 {
  font-size: 14px;
  margin: 0;
}

.card-investimento p {
  font-size: 12px;
  margin: 0;
}

.p-numero {
  font-size: 30px;
  font-weight: 700;
  margin: 0;
}

.p-descricao {
  font-size: 14px;
  color: #52525B;
  margin: 0;
}

.p-responsavel {
  font-size: 12px;
  color: #71717A;
  margin: 0;
}

.p-titulo {
  margin: 0;
}

.p-valor {
  font-weight: 600;
  font-size: 18px;
}

.section-linha {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.item-linha {
  background-color: #fff;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 12px 14px;
  width: 23%;
}

.item-linha:hover {
  cursor: pointer;
}

.css-1rn30mb-MuiCircularProgress-root {
  color: #388933 !important;
}

.container-principal {
  width: 100%;
  height: 89vh;
  margin-top: 7vh;
}



.icon-logout {
  margin-right: 10px;
  font-size: 20px;
}

.demanda-header {
  display: flex;
  flex-direction: row;
  width: 60%;
  margin: 0 auto;
  justify-content: flex-end;
  align-items: center;
  padding: 40px 0 30px;
}

.h1-demanda {
  font-size: 22px;
  margin: 0;
}

.h1-light-demanda {
  font-weight: 400;
  color: #696969;
}

.card-table {
  width: 60%;
  margin: auto;
  max-height: 700px;
  padding: 0 15px 15px 15px;
}

.card-demanda {
  padding: 0 15px 15px 15px;
}

.card-form {
  padding: 15px;
  width: 75%;
  margin: auto;
}


.card-header {
  padding: 15px 5px !important;
  border: none !important;
  font-size: 18px;
}

.table {
  max-height: 400px;
}

td {
  vertical-align: middle;
}

.ul-demanda {
  list-style: none;
  padding: 0px;
}

.td-dem {
  padding: 5px 0px 2px 21px;
  border-bottom: 1px solid #eee;
  /* width: 200px; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tr-prio:hover {
  background-color: #8891a157 !important;
}

.td-prio {
  padding: 5px 0px 2px 21px;
  border-bottom: 1px solid #eee;
}

.tr-ciclo {
  height: 44px;
}

.td-ciclo {
  padding: 5px 0px 2px 21px;
  border-bottom: 1px solid #eee;
}

.e-pager .e-currentitem,
.e-pager .e-currentitem:hover {
  background: #7e7e7e !important;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after {
  display: none !important;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before {
  display: none !important;
}

.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary {
  color: #7e7e7e !important;
}

.e-btn.e-flat.e-primary:hover,
.e-css.e-btn.e-flat.e-primary:hover {
  background-color: rgba(114, 227, 22, 0.04);
  border-color: transparent;
  color: #7e7e7e !important;
}

.css-1o6z5ng {
  padding: 1px 10px !important;
  color: #f1f1f1 !important;
}

.avatar .avatar-initial {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #8592a3;
  font-weight: 700;
}

.rounded-circle {
  border-radius: 50% !important;
}

.bg-label-danger {
  background-color: #ffe0db !important;
  color: #ff3e1d !important;
}

.avatar-sm .avatar-initial {
  font-size: .75rem;
}

.avatar-sm {
  width: 2rem;
  height: 2rem;
}

.avatar {
  position: relative;
  width: 2.375rem;
  height: 2.375rem;
  cursor: pointer;
}

.container-search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.input-search {
  width: 35%;
  padding: 5px;
  border-radius: 5px;
  border: solid 1px #aaa;
  margin-left: 10px;
}

.p-search {
  margin: 0;
}

.btnEditar {
  background-color: #f2f2f2;
  color: black;
  border: 2px solid #f2f2f2;
}

.btnEditar:hover {
  border: 2px solid black;
  background-color: #f2f2f2;
  color: black;
}

.modal-criar-demanda {
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.modal-criar-demanda-interno {
  display: flex;
  flex-direction: column;
  width: 60%;
  border-radius: 5px;
  overflow: auto;
}

.modal-criar-ciclo-interno {
  display: flex;
  flex-direction: column;
  width: 60%;
  border-radius: 5px;
  overflow: auto;
}

.modal-addcoment-interno {
  display: flex;
  flex-direction: column;
  width: 60%;
  background-color: #fff;
  border-radius: 10px;
}

.secao-comentario {
  overflow: auto;
  max-height: 300px;
}

.secao-link {
  overflow: auto;
  max-height: 300px;
}

.secao-risco {
  overflow: auto;
  max-height: 200px;
}

.p-title-comentarios {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
}

.content-comentario {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 8px 16px;
  margin-bottom: 15px;
}

.content-risco {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  padding: 8px 16px;
  color: #71717A;
  align-items: center;
  margin-bottom: 15px;
}

.content-risco p:nth-child(even) {
  margin: 0;
}

.icon-user-comentario {
  margin-right: 5px;
  font-size: 22px;
}

.div-comentario {
  /* margin-left: 100px; */
  width: 40%;
  overflow-wrap: break-word;
  display: flex;
}

.div-comentario p,
.div-link p {
  margin: 0;
}

.div-comentario span,
.div-link span {
  margin-left: 5px;
}

.div-link {
  width: 30%;
  overflow-wrap: break-word;
  display: flex;
}

.div-link a {
  font-weight: 500;
  margin-left: 5px;
}

.div-button {
  display: flex;
  align-items: center;
}

.p-title {
  margin: 0;
  flex-wrap: wrap;
}

.p-content {
  margin: 0;
  font-size: 15px;
  flex-wrap: wrap;
  font-weight: 500;
}

.p-content a {
  font-weight: 500;
}

.modal-novo {
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.modal-novo-interno {
  display: flex;
  flex-direction: column;
  width: 60%;
  background-color: #fff;
  border-radius: 5px;
}

.modal-interno-delete {
  max-height: 800px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
}

.h1-modal {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  padding: 16px;
  border-radius: 8px 0;
}

.h2-modal {
  margin: 0;
  font-size: 15px;
  margin-bottom: 10px;
}

.header-modal-criar-demanda {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px 8px 0 0;
}

.css-rmz4vk {
  background: aliceblue;
  border-radius: 50%;
}

.e-grid {
  border: none;
}

.e-grid .e-gridheader {
  color: black;
}

.header-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px 8px 0 0;
}

.content-buttons {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
}

.modal-content {
  padding: 15px 15px 0 15px;
}

.modal-content-criar-demanda {
  padding: 0 15px 15px 15px;
  overflow: auto;
  max-height: 600px;
}

.nav-tabs {
  position: fixed;
  width: 59%;
  padding-top: 15px;
  z-index: 3;
}

.tab-content {
  margin-top: 70px;
}

.footer-modal {
  border-top: 1px solid #e3e3e3;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-modal-criar-demanda {
  border-top: 1px solid #e3e3e3;
  height: 10%;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-modal-editar-demanda {
  border-top: 1px solid #e3e3e3;
  height: 10%;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-modal-role {
  border-top: 1px solid #e3e3e3;
  height: 10%;
  padding: 15px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-input {
  background: #f1f1f1;
  border: 0px;
  overflow: scroll;
  padding: 5px;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 15%) 1.95px 1.95px 2.6px;
  margin-bottom: 15px;
  min-height: 250px;
}

.modal-label {
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
}

.modal-status {
  margin-left: 5px;
  margin-bottom: 0px;
  font-weight: 600;
}

.dropdown-menu {
  padding: 10px 15px !important;
  min-width: 250px !important;
}

.dropdown-item {
  padding: 10px 0 !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: transparent !important;
}

.span-edit {
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: #14a9e4;
  margin-right: 5px;
  padding: 5px;
  border-radius: 5px;
}

.header-demanda {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  color: white;
  border-radius: 4px 4px 0 0;
}

.filtro-demanda {
  padding: 20px;
  --bs-gutter-x: 0rem !important;
  background-color: #0A1200;
  color: #fff;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-bottom: 30px !important;
}

.container-btn-bsc {
  display: flex;
  justify-content: flex-end;
}

.eye-password {
  cursor: pointer;
}

.modal-checkbox {
  margin-right: 15px;
  font-size: 16px;
  font-weight: 600;
}

.dropdown-toggle::after {
  display: none !important;
}

.checkbox-wrapper .toggleBtn {
  appearance: none;
  background-color: #dfe1e4;
  border-radius: 72px;
  border-style: none;
  flex-shrink: 0;
  height: 20px;
  margin: 0;
  position: relative;
  width: 30px;
}

.checkbox-wrapper .toggleBtn::before {
  bottom: -6px;
  content: "";
  left: -6px;
  position: absolute;
  right: -6px;
  top: -6px;
}

.checkbox-wrapper .toggleBtn,
.checkbox-wrapper .toggleBtn::after {
  transition: all 100ms ease-out;
}

.checkbox-wrapper .toggleBtn::after {
  background-color: #fff;
  border-radius: 50%;
  content: "";
  height: 14px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 14px;
}

.checkbox-wrapper input[type=checkbox] {
  cursor: default;
}

.checkbox-wrapper .toggleBtn:hover {
  background-color: #c9cbcd;
  transition-duration: 0s;
}

.checkbox-wrapper .toggleBtn:checked {
  background-color: #00af0f;
}

.checkbox-wrapper .toggleBtn:checked::after {
  background-color: #fff;
  left: 13px;
}

.checkbox-wrapper :focus:not(.focus-visible) {
  outline: 0;
}

.checkbox-wrapper .toggleBtn:checked:hover {
  background-color: #00af0f;
}

.item-ativo {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 0;
}

.item-inativo {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 0;
}

.container-pages {
  padding: 45px 30px;
  margin-top: 5vh;
  width: 100%;
  min-height: 91vh;
}

.container-dem {
  padding: 30px 30px;
  margin-top: 5vh;
  width: 100%;
  min-height: 91vh;
}

.header-dem {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding: 20px 0;
}

.header-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  width: 75%;
  margin: auto;
}

.h1-pages {
  font-size: 22px;
  margin: 0;
}

.container-bsc {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 25px auto;
}

.section-bsc {
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-bottom: 30px;
  height: 21rem !important;
  overflow: auto;
}

.section-bsc-grafico {
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-bottom: 30px;
  height: 21rem !important;
}

.header-bsc {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tooltip-bsc {
  border-radius: 50%;
  padding: 1px 6px;
  margin-left: 5px;
  color: white;
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
}

.icon-bsc {
  margin-right: 10px;
  width: 30px;
  height: 30px;
  background-color: #F8C23C;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.h2-bsc {
  padding: 15px;
  margin: 0;
  border-radius: 8px 0;
  display: inline-block;
}

::-webkit-scrollbar {
  width: 0;
}

.content-value-bsc {
  border-radius: 4px;
  margin-right: 15px;
  padding: 4px 8px;
}

.value-bsc {
  width: 28px;
  height: 28px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  padding: 5px 0 5px 5px;
}

#custom-css-outlined-input {
  color: #f1f1f1 !important
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color: #f1f1f1 !important
}

.content-bsc {
  height: 80%;
  padding: 0 15px;
}

.td-bsc {
  height: 60px;
  padding: 5px 5px !important;
  border-bottom: 1px solid #52525B;
  font-weight: 500;
}

.td-bsc-ordem {
  height: 60px;
  padding: 5px 10px !important;
  display: flex;
  align-items: center;
}

.tr-bsc {
  border-bottom: 1px solid rgb(237, 242, 249);
}

.tr-bsc:last-child {
  border: none;
}

.th-bsc {
  padding: 10px 0;
  font-weight: 400;
}

.ordem {
  display: flex;
  border: 1px solid #BFDE94;
  border-radius: 2px;
  margin-right: 8px;
}

.ordem div:first-child {
  color: #52525B;
  background-color: #BFDE94;
}

.ordem div {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-gut {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 5px;
  padding: 20px;
}

.pontuacao-gut {
  margin: 0;
  font-size: 13px;
  font-weight: 700;
}

.title-gut {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
}

.number-gut {
  margin: 0;
  font-size: 30px;
  font-weight: 700;
}

.footer {
  /* position: fixed; */
  z-index: 2;
  bottom: 0;
  width: 100%;
  padding: 5px 20px;
  background-color: #f8f8f8;
  color: #7e7e7e;
}

.footer-link {
  text-decoration: none;
  color: #7e7e7e;
}

.nav-tabs .nav-link {
  padding: 0.5rem 0.5rem !important;
}

.nav-link {
  color: #000 !important;
}

.nav-link.active {
  font-weight: 600;
  color: #0F6631 !important;
}

.mb-3 {
  background-color: transparent !important;
}

.input-red {
  background-color: #F87171;
  border-radius: 15px;
}

.input-green {
  background-color: #BFDE94;
  border-radius: 15px;
}

.input-yellow {
  background-color: #FACC15;
  border-radius: 15px;
}

.menu-footer {
  padding: 30px 27px;
  align-self: end;
}

/* .e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input {
  border: 1px solid white;
  border-radius: 5px;
  padding: 6px 12px;
  font-size: 14px;
}

.e-input-focus {
  border: none !important;
}

.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left).e-active {
  border: none;
}

.e-selected {
  color: #002fff;
} */

.e-date-wrapper {
  border-radius: 5px !important;
  padding: 4px 12px !important;
  font-size: 14px !important;
}

.btn-fechar {
  border: none;
  background-color: transparent;
  color: #737373;
}

.btn-att {
  color: white;
  background-color: #3a9ef0;
  border: 1px solid #3a9ef0;
  margin-left: 15px;
  display: flex;
  align-items: center;
}

.btn-cancelar {
  border: 1px solid #FF4553;
  background-color: #FF4553;
  color: white;
  display: flex;
  align-items: center;
}

.btn-close-modal {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 16px;
}

.btn-close-modal:hover {
  background-color: #f44336;
  color: white;
}

.btn-cls-menu {
  padding: 5px 10px;
  border: none;
  background-color: transparent;
}

.btn-comentario {
  color: white;
  background-color: #3a9ef0;
  border: 1px solid #3a9ef0;
  display: flex;
  align-items: center;
}

.btn-copy {
  background-color: transparent;
  border: none;
  transition: all .3s ease-in-out 0s;
  margin-right: 5px;
  border-radius: 5px;
  padding: 3px;
}

.btn-copy:hover {
  background-color: #00000031;
  transform: translateY(-1px);
}

.btn-criar {
  border: none;
  border-radius: 5px;
  color: white;
  margin-left: 15px;
}

.btn-dash {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
}

.btn-del {
  border: none;
  background-color: transparent;
  color: #f44336;
  padding: 2px 5px;
  border-radius: 5px;
  margin: 0 5px;
  font-size: 20px;
  transition: all .3s ease-in-out 0s;
}

.btn-dem {
  border: none;
  background-color: transparent;
  padding: 3px;
  border-radius: 5px;
  margin: 0 3px;
  font-size: 18px;
}

.btn-concluida {
  border: none;
  background-color: transparent;
  padding: 5px;
  border-radius: 5px;
  margin: 0 5px;
  font-size: 16px;
  color: green;
  cursor: default;
}

.btn-cancelada {
  border: none;
  background-color: transparent;
  padding: 5px;
  border-radius: 5px;
  margin: 0 5px;
  font-size: 16px;
  color: red;
  cursor: default;
}

.btn-edit-dropdown {
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.btn-edit-dropdown:hover {
  background-color: #eee;
}

.btn-editor {
  background-color: #0F6631;
  color: white;
  border: none;
  margin-left: 10px;
}

.btn-filtrar {
  border: none;
  background: transparent;
}

.btn-form-salvar {
  color: white;
  background-color: #4CAF50;
  border: 2px solid #4CAF50;
}

.btn-logout {
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #52525B;
}

.btn-modal {
  padding: 5px 15px;
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  transition: all .3s ease-in-out 0s;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 5px;
}

.btn-modal:hover {
  transform: translateY(-1px);
}

.btn-modal-delete {
  padding: 2px 7px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  transition: all .3s ease-in-out 0s;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 5px;
}

.btn-modal-delete:hover {
  transform: translateY(-1px);
}

.btn-password {
  border: none;
  background-color: transparent;
  color: #636363;
  font-weight: 500;
  padding: 15px 0px;
  transition: .5s;
}

.btn-password:hover {
  color: rgb(56, 56, 56);
}

.btn-primary {
  --bs-btn-color: #388933 !important;
  --bs-btn-bg: transparent !important;
  --bs-btn-border-color: transparent !important;
  --bs-btn-hover-color: #388933 !important;
  --bs-btn-hover-bg: transparent !important;
  --bs-btn-hover-border-color:transparent !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #388933 !important;
  --bs-btn-active-bg: transparent !important;
  --bs-btn-active-border-color: transparent !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #388933 !important;
  --bs-btn-disabled-bg: transparent !important;
  --bs-btn-disabled-border-color: transparent !important;
}

.btn-pesquisar {
  color: white;
  background-color: #8AB54E;
  border-radius: 4px;
  border: none;
  display: flex;
  align-items: center;
}

.btn-salvar {
  background-color: #70ad47;
  color: white;
  border: 1px solid #70ad47;
  margin-left: 15px;
  display: flex;
  align-items: center;
}

.btn-ver {
  background-color: transparent;
  border-radius: 5px;
}

.btn-cancel {
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #0F6631;
  color: #0F6631;
}

.e-rte-content {
  min-height: 70vh;
  max-height: 70vh;
  margin-top: 0px;
  overflow: auto;
}

#API_rte-edit-view {
  margin-bottom: 5px;
}

.e-richtexteditor:not(.e-rte-toolbar-enabled) {
  border: 1px solid #ced4da
}

.e-rte-quick-popup .e-rte-dropdown-btn.e-tbar-btn .e-rte-dropdown-btn-text {
  color: black;
}

.e-dropdown-popup ul .e-item {
  color: black;
}

.e-rte-dropdown-btn-text {
  color: black;
}

.input-descricao {
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 5px;
  height: 52px;
  overflow: auto;
}

.modal-rtf {
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 15;
}

.modal-rtf-interno {
  display: flex;
  flex-direction: column;
  width: 60%;
  border-radius: 5px;
  overflow: auto;
  background-color: rgb(248, 248, 248)
}

.modal-rtf-content {
  padding: 20px;
}

.modal-rtf-footer {
  border-top: 1px solid #e3e3e3;
  height: 10%;
  padding: 15px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.e-spinner-pane .e-spinner-inner {
  display: none;
}

.e-popup {
  top: 170px !important;
}

.e-popup-open {
  top: 170px !important;
}

/* media screen */

@media screen and (max-width: 1440px) {
  .modal-content-criar-demanda {
    padding: 0 5px 15px 5px;
  }
}

@media screen and (max-width: 1420px) {
  .nav-tabs .nav-link {
    padding: 0.5rem 0 !important;
  }
}

@media screen and (max-width: 1280px) {
  .item-fases {
    flex-direction: column;
  }

  .icon {
    display: none;
  }

  .p-numero {
    font-size: 22px;
  }

  .nav-tabs {
    width: 94%;
  }

  .modal-criar-demanda-interno {
    width: 95%;
  }

  .row {
    margin: 0;
  }

  .modal-content-criar-demanda {
    padding: 0 10px 15px 10px;
  }

  .filtro-demanda .row>* {
    padding-right: 5px;
    padding-left: 5px;
  }
}

@media screen and (max-width: 1024px) {
  .container-dashboard-row {
    flex-direction: column;
  }

  .dashboard-left {
    width: 100%;
  }

  .container-grafico {
    flex-direction: column;
  }

  .donut {
    width: 100%;
    margin-bottom: 20px;
  }

  .pie {
    width: 100%;
    text-align: center;
  }

  .dashboard-right {
    width: 100%;
    border: none !important;
    padding: 0 30px;
  }

  .status-body {
    font-size: 22px;
  }

  .item-fases {
    width: 15%;
  }

  .h2-bsc {
    font-size: 14px;
  }

  .icon-bsc {
    display: none;
  }

  .th-bsc {
    font-size: 12px;
  }

  .td-bsc {
    font-size: 11px;
  }

  .ordem {
    width: 25px;
    height: 25px;
    font-size: 11px;
  }

  .letra {
    font-size: 14px;
  }

  .descricao {
    font-size: 11px;
  }
}

@media (min-width: 992px) {
  .col-lrg-7 {
    flex: 0 0 auto;
    width: 51.333333%;
  }

  .col-lrg-5 {
    flex: 0 0 auto;
    width: 45.666667%;
  }
}

@media screen and (max-width: 768px) {
  .status-header svg {
    display: none;
  }
}